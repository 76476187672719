@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: 'Mohave', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
    'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@layer components {
  .bg-brandeis {
    @apply bg-[#40A2D8];
  }
  .bg-blupen {
    @apply bg-[#0B60B0];
  }
  .border-brandeis {
    @apply border-[#40A2D8];
  }
  .border-blupen {
    @apply border-[#0B60B0];
  }
  .text-brandeis {
    @apply text-[#40A2D8];
  }
  .text-blupen {
    @apply text-[#0B60B0];
  }
}
